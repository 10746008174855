"use client";

import React, { useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { useSearchForm } from "../../(HeroSearchForm)/SearchFormContext";
import InfoNameInput from "../../(HeroSearchForm)/InfoNameInput";

export type TypeCategory =
  | "Mariage"
  | "Anniversaire"
  | "Demande en mariage"
  | "Séminaire"
  | "EVJF/EVG"
  | "Tournage & Production"
  | "";

const MobileStaySearchForm = () => {
  const [fieldNameShow, setFieldNameShow] = useState<
    | "location"
    | "dates"
    | "guests"
    | "eventtype"
    | "name"
    | "phone"
    | "email"
    | ""
  >("eventtype");
  const { eventtype, setEventType } = useSearchForm();
  const shouldShowPersonalInfo =
    eventtype === "Mariage" || eventtype === "Séminaire";

  const categoryOptions: TypeCategory[] = [
    "Mariage",
    "Anniversaire",
    "Demande en mariage",
    "Séminaire",
    "EVJF/EVG",
    "Tournage & Production",
  ];

  // Handler for date selection
  const handleDateSelected = () => {
    if (shouldShowPersonalInfo) {
      setFieldNameShow("name");
    } else {
      setFieldNameShow("guests");
    }
  };

  const renderInputField = (
    label: string,
    value: string,
    onClick: () => void,
    active: boolean,
    children: React.ReactNode
  ) => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        active
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      {!active ? (
        <button
          className="w-full flex justify-between text-sm font-medium p-4"
          onClick={onClick}
        >
          <span className="text-neutral-400">{label}</span>
          <span>{value}</span>
        </button>
      ) : (
        <div className="max-h-[60vh] overflow-y-auto">{children}</div>
      )}
    </div>
  );

  const renderEventInput = () => (
    <div className="flex flex-wrap gap-2 p-4">
      {categoryOptions.map((option) => (
        <button
          key={option}
          className={`py-1.5 px-4 rounded-full text-xs font-medium ${
            eventtype === option
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => {
            setEventType(option);
            setFieldNameShow("location");
          }}
        >
          {option}
        </button>
      ))}
    </div>
  );

  const getPlaceHolder = (type: string) => {
    switch (type) {
      case "Mariage":
        return "Lieu de votre salle de mariage";
      case "Séminaire":
        return "Lieu de votre évènement";
      case "Tournage & Production":
        return "Lieu de tournage";
      case "Anniversaire":
      case "Demande en mariage":
      case "EVJF/EVG":
      default:
        return "À découvrir à proximité";
    }
  };

  const getDescription = (type: string) => {
    switch (type) {
      case "Mariage":
        return "Où se trouve votre salle de mariage?";
      case "Séminaire":
        return "Où se trouve votre lieu d'évènement?";
      case "Tournage & Production":
        return "Où se trouve votre lieu de tournage?";
      case "Anniversaire":
      case "Demande en mariage":
      case "EVJF/EVG":
      default:
        return "Saisissez votre ville";
    }
  };

  return (
    <div className="space-y-4 h-full overflow-y-auto">
      {renderInputField(
        "Type d'événement",
        eventtype,
        () => setFieldNameShow("eventtype"),
        fieldNameShow === "eventtype",
        renderEventInput()
      )}
      {renderInputField(
        "Où",
        getPlaceHolder(eventtype),
        () => setFieldNameShow("location"),
        fieldNameShow === "location",
        <LocationInput
          desc={getDescription(eventtype)}
          headingText={getPlaceHolder(eventtype)}
          onLocationSelected={() => setFieldNameShow("dates")}
        />
      )}
      {renderInputField(
        "Quand",
        "Choisir une date",
        () => setFieldNameShow("dates"),
        fieldNameShow === "dates",
        <DatesRangeInput
          selectsRange={false}
          href="/listing-flights"
          onDateSelected={handleDateSelected}
        />
      )}

      {/* Only render personal info fields if eventtype is "Mariage" or "Séminaire" */}
      {shouldShowPersonalInfo && fieldNameShow !== "guests" && (
        <>
          {renderInputField(
            "Prénom, Nom",
            "",
            () => setFieldNameShow("name"),
            fieldNameShow === "name",
            <InfoNameInput
              placeHolder="Prénom, Nom"
              desc="Renseignez votre nom et prénom"
              className="flex-1"
              type="name"
            />
          )}
          {renderInputField(
            "N° de téléphone",
            "",
            () => setFieldNameShow("phone"),
            fieldNameShow === "phone",
            <InfoNameInput
              placeHolder="N° de téléphone"
              desc="Renseignez votre n° de téléphone"
              className="flex-1"
              type="phone"
            />
          )}
          {renderInputField(
            "Adresse e-mail",
            "",
            () => setFieldNameShow("email"),
            fieldNameShow === "email",
            <InfoNameInput
              placeHolder="Adresse e-mail"
              desc="Renseignez votre e-mail"
              className="flex-1"
              type="email"
            />
          )}
        </>
      )}

      {renderInputField(
        "Passagers",
        "Ajouter des Passagers",
        () => setFieldNameShow("guests"),
        fieldNameShow === "guests",
        <GuestsInput />
      )}
    </div>
  );
};

export default MobileStaySearchForm;
