"use client";

import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { useSearchForm } from "../(HeroSearchForm)/SearchFormContext";
// import ClearDataButton from "./ClearDataButton";

interface Props {
  className?: string;
  headingText?: string;
  desc?: string;
  onLocationSelected?: () => void; // Propriété conservée
}

const LocationInput: FC<Props> = ({
  className = "",
  headingText = "À découvrir à proximité",
  desc = "Saisissez votre ville",
  onLocationSelected,
}) => {
  const { location, setLocation, setLat, setLng } = useSearchForm();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(location || "");
  const [showPopover, setShowPopover] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  // Liste des villes prédéfinies avec leurs coordonnées
  const PREDEFINED_CITIES = [
    { description: "Paris, France", lat: 48.8566, lng: 2.3522 },
    { description: "Marseille, France", lat: 43.2965, lng: 5.3698 },
    { description: "Lyon, France", lat: 45.7640, lng: 4.8357 },
  ];

  useEffect(() => {
    setValue(location || "");
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowPopover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value.length > 2 && window.google) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input: value, language: "fr" },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions || []);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  }, [value]);

  const handleSelectLocation = (item: any) => {
    const selectedValue = item.description || item.structured_formatting?.main_text;
    setValue(selectedValue);
    setShowPopover(false);
    setLocation(selectedValue);

    // Vérifier si la sélection correspond à une ville prédéfinie
    const predefinedCity = PREDEFINED_CITIES.find(city => city.description === selectedValue);
    if (predefinedCity) {
      setLat(predefinedCity.lat);
      setLng(predefinedCity.lng);
      // Appeler le callback onLocationSelected si fourni
      if (onLocationSelected) {
        onLocationSelected();
      }
    } else {
      // Pour les autres cas, utiliser le Geocoder
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: item.place_id }, (results, status) => {
        if (status === "OK" && results && results[0]) {
          const { lat, lng } = results[0].geometry.location;
          setLat(lat());
          setLng(lng());
          // Appeler le callback onLocationSelected si fourni
          if (onLocationSelected) {
            onLocationSelected();
          }
        } else {
          console.error("Erreur lors de la récupération des coordonnées :", status);
        }
      });
    }
  };

  const renderSearchValues = () => {
    return (
      <div className="mt-3">
        {suggestions.map((item) => (
          <div
            className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700"
            onClick={() => handleSelectLocation(item)}
            key={item.place_id}
          >
            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span>{item.description}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderRecentSearches = () => {
    return (
      <div className="mt-3">
        <div className="py-2 mb-1 flex items-center space-x-3 text-sm text-neutral-500 dark:text-neutral-400">
          <span>Suggestions</span>
        </div>
        {PREDEFINED_CITIES.map((item) => (
          <div
            className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700"
            onClick={() => handleSelectLocation(item)}
            key={item.description}
          >
            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span>{item.description}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">{headingText}</span>
        <div className="relative mt-5">
          <input
            className="block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate"
            placeholder={desc}
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              setShowPopover(true);
            }}
            ref={inputRef}
            onFocus={() => setShowPopover(true)}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
          {/* {value && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                setLocation("");
                setLat(null);
                setLng(null);
              }}
            />
          )} */}
        </div>
        {showPopover && (
          <div className="mt-7">
            {value ? renderSearchValues() : renderRecentSearches()}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationInput;