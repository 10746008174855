import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePickerCustomHeaderOneMonth from "@/components/DatePickerCustomHeaderOneMonth";
import DatePickerCustomDay from "@/components/DatePickerCustomDay";
import ButtonSubmit from "./ButtonSubmit";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import { Route } from "@/routers/types";
import { useSearchForm } from "../(HeroSearchForm)/SearchFormContext";
import DatePickerCustomHeaderTwoMonth from "@/components/DatePickerCustomHeaderTwoMonth";

registerLocale("fr", fr);
setDefaultLocale("fr");

export interface FlightDateRangeInputProps {
  className?: string;
  hasButtonSubmit?: boolean;
  selectsRange?: boolean;
  href?: string;
  onDateSelected?: () => void; // New prop to handle date selection callback
}

const FlightDateRangeInput: React.FC<FlightDateRangeInputProps> = ({
  className = "",
  hasButtonSubmit = false,
  selectsRange = false,
  href = "",
  onDateSelected,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(selectsRange ? new Date() : null);
  const [isOpen, setIsOpen] = useState(false);
  const { setDate, setDateRetour } = useSearchForm();

  const onChangeRangeDate = (
    dates: [Date | null, Date | null] | null
  ) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      setDate(start?.toLocaleDateString("fr-FR", {}) || "");
      setDateRetour(end?.toLocaleDateString("fr-FR", {}) || "");
      
      // If both dates are selected and onDateSelected callback exists, call it
      if (end && onDateSelected) {
        onDateSelected();
      }
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const onChangeSingleDate = (date: Date | null) => {
    setStartDate(date);
    setDate(date?.toLocaleDateString("fr-FR", {}) || "");
    
    // If date is selected and onDateSelected callback exists, call it
    if (date && onDateSelected) {
      onDateSelected();
    }
  };

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const renderInput = () => {
    return (
      <div className="flex items-center space-x-3 p-4 bg-white dark:bg-neutral-800 rounded-lg shadow-sm">
        <CalendarIcon className="w-6 h-6 text-neutral-500" />
        <div className="flex-grow">
          <span className="block text-lg font-semibold">
            {startDate?.toLocaleDateString("fr-FR", {
              month: "short",
              day: "2-digit",
            }) || "Ajouter une date"}
            {selectsRange && endDate
              ? " - " +
                endDate?.toLocaleDateString("fr-FR", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block text-sm text-neutral-500">
            {selectsRange ? "Du - Au" : "Choisissez une date"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`FlightDateRangeInput ${className}`}>
      <div className="p-5">
        <span className="block font-semibold text-xl">Choisissez une date</span>
      </div>
      <div onClick={toggleDatePicker}>{renderInput()}</div>
      <div className="mt-4">
        <DatePicker
          selected={startDate}
          onChange={selectsRange ? onChangeRangeDate : onChangeSingleDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange={selectsRange}
          monthsShown={selectsRange ? 2 : 1}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => 
            selectsRange 
              ? <DatePickerCustomHeaderTwoMonth {...p} /> 
              : <DatePickerCustomHeaderOneMonth {...p} />
          }
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
      {hasButtonSubmit && (
        <div className="mt-4">
          <ButtonSubmit href={href as Route} />
        </div>
      )}
    </div>
  );
};

export default FlightDateRangeInput;